import { ValidationSchemaLevel } from 'types'
import * as yup from 'yup'

const laxSchema = {
  fileLanguage: yup
    .object()
    .required('documentForm.errorRequired')
    .nullable()
    .shape({
      id: yup.string().required('documentForm.errorRequired'),
    }),
  type: yup.object().required('documentForm.errorRequired').nullable(),
  metadata: yup.array().of(
    yup.object({
      // GENERAL
      language: yup.string().notRequired(),
      title: yup
        .string()
        .required('documentForm.errorRequired')
        .max(50, 'documentForm.errorTitleLength'),
      longTitle: yup
        .string()
        .max(255, 'documentForm.errorLongTitleLength')
        .notRequired(),
      description: yup
        .string()
        .max(1000, 'documentForm.errorDescriptionLength')
        .notRequired(),
      code: yup.string().required('documentForm.errorRequired'),

      // SCOPE
      departments: yup.array().of(yup.string()).notRequired(),

      targetGroup: yup
        .string()
        .notRequired()
        .min(3, 'guidelineForm.errorTargetGroupLength'),

      locations: yup.array().of(yup.string()).notRequired(),

      countries: yup.array().of(yup.string()).notRequired(),

      legalEntities: yup.array().of(yup.string()).notRequired(),

      effectiveAt: yup.string().notRequired(),

      keywords: yup.array().of(yup.string()).notRequired(),

      // TOPIC
      mainTopic: yup.object().nullable().required('documentForm.errorRequired'),
      altTopic: yup.array().of(yup.string()).notRequired(),
      // REFERENCES
      accessRights: yup
        .object()
        .nullable()
        .required('documentForm.errorRequired'),
      internalReferenceLinks: yup.array().of(yup.string()).notRequired(),
      collections: yup.array().of(yup.string()).notRequired(),
      externalReferenceLinks: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            value: yup.string().nullable(),
            name: yup.string().notRequired().nullable(),
          })
        ),
      obsoletePractices: yup.array().of(yup.string()).notRequired(),
      certificationScope: yup.array().of(yup.string()).notRequired(),

      // CONTACT
      contact: yup.object().nullable().required('documentForm.errorRequired'),
    })
  ),
}

const strictSchema = {
  fileLanguage: yup
    .object()
    .required('documentForm.errorRequired')
    .nullable()
    .shape({
      id: yup.string().required('documentForm.errorRequired'),
    }),
  type: yup.object().required('documentForm.errorRequired').nullable(),
  metadata: yup.array().of(
    yup.object({
      // GENERAL
      language: yup.string().required('guidelineForm.errorRequired'),
      title: yup
        .string()
        .required('guidelineForm.errorRequired')
        .max(50, 'guidelineForm.errorTitleLength'),
      longTitle: yup
        .string()
        .max(255, 'guidelineForm.errorLongTitleLength')
        .notRequired(),
      description: yup
        .string()
        .max(1000, 'guidelineForm.errorDescriptionLength')
        .required('guidelineForm.errorRequired'),
      code: yup.string().required('guidelineForm.errorRequired'),

      // SCOPE
      departments: yup
        .array()
        .of(yup.string())
        .min(1, 'guidelineForm.errorRequired')
        .required('guidelineForm.errorRequired'),

      targetGroup: yup
        .string()
        .min(3, 'guidelineFrom.errorTargetGroupLength')
        .required('guidelineForm.errorRequired'),
      locations: yup
        .array()
        .of(yup.string())
        .min(1, 'guidelineForm.errorRequired')
        .required('guidelineForm.errorRequired'),
      countries: yup
        .array()
        .of(yup.string())
        .min(1, 'guidelineForm.errorRequired')
        .required('guidelineForm.errorRequired'),
      legalEntities: yup
        .array()
        .of(yup.string())
        .min(1, 'guidelineForm.errorRequired')
        .required('guidelineForm.errorRequired'),
      effectiveAt: yup.string().required('guidelineForm.errorRequired'),
      keywords: yup
        .array()
        .of(yup.string())
        .required('guidelineForm.errorRequired'),

      // TOPIC
      mainTopic: yup
        .object()
        .nullable()
        .required('guidelineForm.errorRequired'),
      altTopic: yup.array().of(yup.string()).notRequired(),

      // REFERENCES
      accessRights: yup
        .object()
        .nullable()
        .required('guidelineForm.errorRequired'),
      internalReferenceLinks: yup
        .array()
        .of(yup.string())
        .notRequired()
        .nullable(),
      collections: yup.array().of(yup.string()).notRequired().nullable(),
      externalReferenceLinks: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            value: yup.string().nullable(),
            name: yup.string().notRequired().nullable(),
          })
        ),
      obsoletePractices: yup.array().of(yup.string()).notRequired().nullable(),
      certificationScope: yup.array().of(yup.string()).notRequired().nullable(),

      // CONTACT
      contact: yup.object().nullable().required('guidelineForm.errorRequired'),
    })
  ),
}

export const documentFormSchema = (level: ValidationSchemaLevel) => {
  if (level === 'lax') {
    return yup.object().shape(laxSchema)
  }

  return yup.object().shape(strictSchema)
}
